import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getDataForTransformationsQuery } from '../../../../queries';
import CloseIcon from '../../../../public/static/images/new_search/icons/icon-reset.svg';
import Icon from '../../../Icon';
import NewRadioButton from '../../../NewRadioButton';
import NumberField from '../NumberField';
import Button from '../../../Button';
import { getValueFromObject, toQueryString } from '../../../../utils/helpers';
import { setSearchSort } from '../../../../actions';
import CheckboxButton from '../../../CheckboxButton';
import { useLockBodyScroll } from '../../../../hooks/useLockBodyScroll';
import { useWithNumberField } from '../../../../hooks/useWithNumberField';
import Portal from '../../../ReactModal/Portal';

export const sortingVariants = t => [
  { id: '1', name: t('new_search.sort.by_date') },
  { id: '2', name: t('new_search.sort.by_area_asc') },
  { id: '3', name: t('new_search.sort.by_price_asc') },
  { id: '4', name: t('new_search.sort.by_price_desc') }
];

const NewSorting = ({ hide }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const currentSort = useSelector(state => state.searchForm.currentSortType);
  const { data } = useQuery(getDataForTransformationsQuery, {
    fetchPolicy: 'cache-only'
  });

  const onChangeRadioHandler = e => {
    const { search, ...queryObj } = router.query;
    const { url, ...newQueryObj } = queryObj;

    const query = (concatSign = '?') => {
      newQueryObj.sorting = getValueFromObject(
        data.sort,
        e.target.value,
        'params'
      );

      return !Object.keys(newQueryObj).length
        ? ''
        : `${concatSign}${toQueryString(newQueryObj)}`;
    };

    const href = `/[...search]?url=${search.join('/')}${query('&')}`.replace(
      /\/\//g,
      '/'
    );
    const as = `/${search.join('/')}${query()}`.replace(/\/\//g, '/');

    dispatch(setSearchSort(e.target.value));
    router.push(href, as);
    hide();
  };

  return (
    <>
      {sortingVariants(t).map(sorting => (
        <NewRadioButton
          data={sorting}
          key={sorting.id}
          cypress="sorting"
          name="search-city"
          currentValue={currentSort || '1'}
          onChangeHandler={onChangeRadioHandler}
          containerClassNames="filter-modal__radio"
        />
      ))}
    </>
  );
};

NewSorting.propTypes = {
  hide: PropTypes.func
};

const NewPrice = ({ children, hide }) => {
  const { t } = useTranslation();
  const { from, setFrom, to, setTo, onSubmit, fromRef, canReset, onReset } =
    useWithNumberField('price_from', 'price_to', hide);

  return (
    <>
      <p className="filters__secondary-text">{t('search.form.price')}</p>
      <div className="filters__fields-container">
        <NumberField
          ref={fromRef}
          name="new-search-form-price-from"
          id="new-search-form-price-from"
          placeholder={t('search.form.from')}
          fieldValue={from}
          onChange={setFrom}
          onReset={() => setFrom('')}
        />
        <NumberField
          name="new-search-form-price-to"
          id="new-search-form-price-to"
          placeholder={t('search.form.to')}
          fieldValue={to}
          onChange={setTo}
          onReset={() => setTo('')}
        />
      </div>
      <div className="filter-modal__action-buttons">
        <Button
          appearance="blueOutlined"
          disabled={!canReset}
          onClickHandler={onReset}
          cypress="reset-btn"
        >
          {t('common.reset')}
        </Button>
        <Button
          appearance="blueFilled"
          onClickHandler={onSubmit}
          cypress="apply-btn"
        >
          {children}
        </Button>
      </div>
    </>
  );
};

NewPrice.propTypes = {
  children: PropTypes.string,
  hide: PropTypes.func
};

const NewArea = ({ children, hide }) => {
  const { t } = useTranslation();
  const {
    from,
    setFrom,
    to,
    setTo,
    onSubmit,
    fromRef,
    values,
    canReset,
    onReset
  } = useWithNumberField('area_from', 'area_to', hide);

  const isLand = values.category_id === '9';

  return (
    <>
      <p className="filters__secondary-text">
        {t('search.form.area')}
        <span> </span>
        {isLand ? t('search.form.units.acres') : t('search.form.units.metres')}
      </p>
      <div className="filters__fields-container">
        <NumberField
          id="new-search-form-area-from"
          ref={fromRef}
          name="from"
          placeholder={t('search.form.from')}
          fieldValue={from}
          onChange={setFrom}
          onReset={() => setFrom('')}
        />
        <NumberField
          id="new-search-form-area-to"
          name="to"
          placeholder={t('search.form.to')}
          fieldValue={to}
          onChange={setTo}
          onReset={() => setTo('')}
        />
      </div>
      <div className="filter-modal__action-buttons">
        <Button
          appearance="blueOutlined"
          disabled={!canReset}
          onClickHandler={onReset}
          cypress="reset-btn"
        >
          {t('common.reset')}
        </Button>
        <Button
          appearance="blueFilled"
          onClickHandler={onSubmit}
          cypress="apply-btn"
        >
          {children}
        </Button>
      </div>
    </>
  );
};

NewArea.propTypes = {
  children: PropTypes.string,
  hide: PropTypes.func
};

const buildingType = t => [
  { id: '2', name: t('new_search.new_building') },
  { id: '3', name: t('new_search.secondary_building') }
];

const NewBuildingType = ({ children, hide }) => {
  const { t } = useTranslation();
  const { change, submit } = useForm();
  const { values } = useFormState();
  const [categoryId, setCategoryId] = useState(values.category_id || '');

  const onChangeRadioHandler = e => {
    setCategoryId(e.target.value);
  };

  const onSubmit = () => {
    change('category_id', categoryId);
    submit();
    hide();
  };

  return (
    <>
      {buildingType(t).map(type => (
        <NewRadioButton
          key={type.id}
          data={type}
          cypress="building"
          onChangeHandler={onChangeRadioHandler}
          currentValue={categoryId}
          name="category_id"
        />
      ))}
      <Button
        onClickHandler={onSubmit}
        additionalClass="block-with-margin filter-modal__apply-btn"
      >
        {children}
      </Button>
    </>
  );
};

NewBuildingType.propTypes = {
  children: PropTypes.string,
  hide: PropTypes.func
};

const roomsCount = t => [
  {
    id: '1',
    fullName: t('quick_links.room_count', { count: 1, postProcess: 'interval' })
  },
  {
    id: '2',
    fullName: t('quick_links.room_count', { count: 2, postProcess: 'interval' })
  },
  {
    id: '3',
    fullName: t('quick_links.room_count', { count: 3, postProcess: 'interval' })
  },
  {
    id: '4',
    fullName: t('quick_links.room_count', { count: 4, postProcess: 'interval' })
  },
  {
    id: '5+',
    fullName: t('quick_links.room_count_plus', {
      count: 5,
      postProcess: 'interval'
    })
  }
];

const RoomsCount = ({ children, hide }) => {
  const { t } = useTranslation();
  const { change, submit } = useForm();
  const { values } = useFormState();
  const [roomIds, setRoomIds] = useState(values.room_ids || []);

  const onChange = e => {
    setRoomIds(prev => {
      let result;
      if (prev.includes(e.target.value)) {
        result = prev.filter(id => id !== e.target.value);
      } else {
        result = [...prev, e.target.value];
      }

      return result.sort((a, b) => {
        if (a === '5+') return 1;
        if (b === '5+') return -1;
        return parseInt(a, 10) - parseInt(b, 10);
      });
    });
  };

  function onReset() {
    setRoomIds([]);
    change('room_ids');
    submit();
    hide();
  }

  const onSubmit = () => {
    change('room_ids', roomIds);
    submit();
    hide();
  };

  return (
    <>
      {roomsCount(t).map(roomCount => (
        <CheckboxButton
          name="room_ids"
          key={roomCount.id}
          data={roomCount}
          cypress="room-сount"
          currentValues={roomIds}
          onChangeHandler={onChange}
          stat={`new-search-form-${roomCount.id}-rooms`}
          labelClass="filter-modal__checkbox-label"
          isFullName
        />
      ))}
      <div className="filter-modal__action-buttons filter-modal__action-buttons_with-top-m">
        <Button
          appearance="blueOutlined"
          disabled={!roomIds.length}
          onClickHandler={onReset}
        >
          {t('common.reset')}
        </Button>
        <Button
          appearance="blueFilled"
          onClickHandler={onSubmit}
          cypress="apply-btn"
        >
          {children}
        </Button>
      </div>
    </>
  );
};

RoomsCount.propTypes = {
  children: PropTypes.string,
  hide: PropTypes.func
};

const configs = {
  sorting: { title: 'search.buttons.sort', Component: NewSorting },
  price: { title: 'new_search.price', Component: NewPrice },
  area: { title: 'new_search.area', Component: NewArea },
  roomsCount: { title: 'search.form.rooms_number', Component: RoomsCount },
  buildingType: {
    title: 'search.form.building_type_group.title',
    Component: NewBuildingType
  }
};

export const FilterModal = ({ hide, isOpen, modalConfig }) => {
  useLockBodyScroll(isOpen);

  const { t } = useTranslation();

  if (!isOpen) return null;

  const content = configs[modalConfig];

  return (
    <Portal id={modalConfig} portalClassName="filter-modal__portal">
      <div
        tabIndex={0}
        role="button"
        className="filter-modal__overlay"
        onKeyDown={hide}
        onClick={hide}
      />
      <div className="filter-modal__position-box">
        <div className="filter-modal">
          <div className="filter-modal__header">
            {t(content.title)}

            <button className="filter-modal__header__close-btn" onClick={hide}>
              <Icon IconComponent={CloseIcon} indent={false} />
            </button>
          </div>
          <div className="filter-modal__content">
            <content.Component hide={hide}>
              {t('common.apply')}
            </content.Component>
          </div>
        </div>
      </div>
    </Portal>
  );
};

FilterModal.propTypes = {
  hide: PropTypes.func,
  isOpen: PropTypes.bool,
  modalConfig: PropTypes.string
};
